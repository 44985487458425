.fondostatico {
  background-color: rgba(240, 248, 255, 0.433);
  border-radius: 10px;
}

.clean-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.show-mod {
  display: block;
}
label {
  margin-bottom: 0px;
  margin-left: 8px;
}
.full-hd {
  height: 100vh;
}